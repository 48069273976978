import React from "react"
import styled from "styled-components"

import { SEO } from "../../components"
import { WorkerslifeCareersPage, CurrentOpenings } from "../../sections/careers"

const WorkerslifeCareers: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Careers"
        description="Discover exciting career opportunities at Workerslife. Collaborate, innovate, and make a meaningful impact. Explore current openings now!"
      />
      <WorkerslifeCareersPage />
      <CurrentOpenings />
    </PageWrapper>
  )
}

export default WorkerslifeCareers

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
  .sectionHeader {
    margin: 40px auto 0 auto;
    max-width: 970px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }
  }
`
