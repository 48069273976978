import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { Article } from "../../components"

const WorkerslifeCareersPage: React.FC = () => {
  return (
    <Container>
      <Article
        title="Work for Workerslife..."
        image={
          <StaticImage
            src="../../assets/images/careers/career-1.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
      >
        <p>
          Do you want to be a member of the Workerslife team? We believe in
          teamwork and collaboration – putting our heads together to set
          ourselves ambitious challenges.
        </p>
        <p>
          We also believe in the different perspectives that individuals bring.
          So, ours is a space where you also have the freedom to work alone and
          to see your own contribution make an impact.
        </p>
      </Article>
      {/* <div className="sectionHeader">
        <h2>Embark on a Rewarding Career Journey with Workerslife!</h2>
      </div>
      <ArticleNoTitle
        image={
          <StaticImage
            src="../../assets/images/careers/career-2.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
        imageLeft={true}
      >
        <p>
          Are you ready to become an integral part of the Workerslife team?
          Working at Workerslife isn&apos;t just about a job; it&apos;s about
          being a valued member of a dynamic, collaborative, and ambitious
          community. We believe in the power of teamwork, where collective
          efforts are the driving force behind achieving challenging goals.
        </p>
      </ArticleNoTitle>
      <ArticleNoTitle
        image={
          <StaticImage
            src="../../assets/images/careers/career-3.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
      >
        <p>
          At Workerslife, diversity is not just welcomed; it&apos;s celebrated.
          We understand that individual perspectives bring richness to our work
          environment. In our space, you&apos;ll experience the perfect blend of
          collaborative spirit and the freedom to work independently, witnessing
          firsthand how your contributions can make a tangible impact.
        </p>
      </ArticleNoTitle>
      <ArticleNoTitle
        image={
          <StaticImage
            src="../../assets/images/careers/career-4.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
        imageLeft={true}
      >
        <p>
          We encourage our team members to take ownership of their work,
          fostering an environment where innovation thrives. Joining Workerslife
          means being part of a company that values your individuality,
          appreciates your efforts, and provides a platform for your
          professional growth.
        </p>
      </ArticleNoTitle>
      <ArticleNoTitle
        image={
          <StaticImage
            src="../../assets/images/careers/career-5.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
      >
        <p>
          If you&apos;re passionate about making a meaningful impact, embracing
          challenges, and contributing to the success of a progressive company,
          Workerslife is the place for you.
        </p>
        <p>
          Our current job opportunities await individuals who are eager to shape
          the future of insurance services. Start your career journey with
          Workerslife and be part of a team that is dedicated to redefining the
          landscape of the insurance industry. Your exciting career adventure
          begins here!
        </p>
      </ArticleNoTitle> */}
    </Container>
  )
}

export default WorkerslifeCareersPage

const Container = styled.div`
  padding: 0 20px;
  display: grid;
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 140px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;
  }
`
