import React from "react"
import styled from "styled-components"
import { StyledLink } from "../../elements"

export const CurrentOpenings = (): JSX.Element => {
  return (
    <Container>
      <h3>Current Openings</h3>
      <p>We currently have no positions available. Check back soon…</p>
      {/* Example : */}
      {/* <h4>General Manager</h4>
      <StyledLink
        label="Learn more"
        link="/workerslife-careers/general-manager-vacancy"
        arrowColor="blue"
      /> */}
    </Container>
  )
}

export default CurrentOpenings

const Container = styled.div`
  padding: 0 20px;

  h3 {
    margin-bottom: 38px;
    text-align: center;
    font-weight: 800;
  }
  p {
    font-family: "Sofia Pro";
    font-style: italic;
    font-weight: 400;
    text-align: center;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 99px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;

    h3 {
      font-size: 52px;
      line-height: 69px;
    }

    p {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 40px;
    }
  }
`
